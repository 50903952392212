import React, { PropsWithChildren, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./assets/css/App.css";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
  IPublicClientApplication,
} from "@azure/msal-browser";
import { loginRequest, msalConfig, silentRequest } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";
import { BrowserRouter, useNavigate } from "react-router-dom";
import CustomNavigationClient from "utils/NavigationClient";
import Router from "routes/Router";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";
import { ApolloProvider } from "@apollo/client";
import store from "./redux/store";
import { Provider } from "react-redux";
import { ApolloClient, InMemoryCache, HttpLink, split, ApolloLink } from '@apollo/client';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { setContext } from '@apollo/client/link/context';

console.log('ENV: ', process.env);

type AppProps = {
  pca: IPublicClientApplication;
};

const ClientSideNavigation: React.FC<AppProps & PropsWithChildren> = ({
  pca,
  children,
}) => {
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  // react-router-dom v6 doesn't allow navigation on the first render - delay rendering of MsalProvider to get around this limitation
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    setFirstRender(false);
  }, []);

  if (firstRender) {
    return null;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

// MSAL setup
// eslint-disable-next-line import/prefer-default-export
export const msalInstance = new PublicClientApplication(msalConfig);

const accounts = msalInstance.getAllAccounts();

if (!msalInstance.getActiveAccount() && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

// Optional - This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const { account } = payload;
    msalInstance.setActiveAccount(account);
  } else {
    console.log(event.error);
  }
});

msalInstance.handleRedirectPromise().catch((err) => console.error(err));

const authLink = setContext(async (_, { headers }) => {
  
  const account = msalInstance.getAllAccounts()[0];

  if (!account) {
    throw new Error("No account found");
  }

  const response = await msalInstance.acquireTokenSilent({
    scopes: [`https://${process.env.REACT_APP_B2C_DOMAIN_NAME}/InvestorPortal.API/Read.Data`],
  });

  const accessToken = response.accessToken;

  return {
    headers: {
      ...headers,
      authorization: accessToken ? `Bearer ${accessToken}` : "",
      'GraphQL-preflight': '1',
    }
  }
});

const httpLink = new HttpLink({
  uri: `${process.env.REACT_APP_INVESTMENTPORTAL_URL}/graphql`,
  credentials: 'include',
});

const uploadLink: any = createUploadLink({
  uri: `${process.env.REACT_APP_INVESTMENTPORTAL_URL}/graphql`,
  credentials: 'include',
})

const uploadLinkWithHeaders = authLink.concat(uploadLink);
const httpLinkWithHeaders = authLink.concat(httpLink);

const isFile = (value: any) =>
  (typeof File !== "undefined" && value instanceof File) ||
(typeof Blob !== "undefined" && value instanceof Blob);

const isUpload = ({variables}: {variables: any}) => Object.values(variables).some(isFile);

const terminalLink = split(isUpload, uploadLinkWithHeaders, httpLinkWithHeaders)

export const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: ApolloLink.from([terminalLink]),
});

ReactDOM.render(
  <CacheBuster
    currentVersion={packageInfo.version}
    isEnabled={true} //If false, the library is disabled.
    isVerboseMode={false} //If true, the library writes verbose logs to console.
    metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
  >
    <BrowserRouter>
      <ClientSideNavigation pca={msalInstance}>
        <MsalProvider instance={msalInstance}>
          <ChakraProvider theme={theme}>
            <Provider store={store}>
              <ApolloProvider client={client}>
                <React.StrictMode>
                  <Router />
                </React.StrictMode>
              </ApolloProvider>
            </Provider>
          </ChakraProvider>
        </MsalProvider>
      </ClientSideNavigation>
    </BrowserRouter>
  </CacheBuster>,
  document.getElementById("root")
);

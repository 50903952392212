import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  useDisclosure,
  InputGroup,
  IconButton,
  InputRightElement,
  Flex,
} from "@chakra-ui/react";
import { ChevronDownIcon, CloseIcon } from "@chakra-ui/icons";

interface SearchableComboBoxProps {
  items: string[];
  handleItemSelection: (item: string) => void;
}

const SearchableComboBox: React.FC<SearchableComboBoxProps> = (
  props: SearchableComboBoxProps
) => {
  const { items, handleItemSelection } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const inputRef = useRef(null);

  const filteredItems = items.filter((item) =>
    item.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleSelectItem = (item: string) => {
    setSelectedItem(item);
    handleItemSelection(item);
    setSearchQuery("");
    onClose();
  };

  useEffect(() => {
    // When typing, reset the highlighted index
    setHighlightedIndex(-1);
  }, [searchQuery]);

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "ArrowDown") {
      // On ArrowDown, move the highlight to the first item if no item is highlighted
      setHighlightedIndex((prevIndex) =>
        prevIndex < filteredItems.length - 1 ? prevIndex + 1 : 0
      );
    } else if (event.key === "Enter" && highlightedIndex >= 0) {
      // On Enter, select the highlighted item
      handleSelectItem(filteredItems[highlightedIndex]);
    }
  };

  const clearSearch = () => {
    setSearchQuery("");
    inputRef.current.focus(); // Re-focus the input after clearing
  };

  const clearSelectedItem = (e: any) => {
    e.stopPropagation(); // Prevent the click from opening the menu
    setSelectedItem(null);
    handleItemSelection(null);
  };

  return (
    <Box width="300px" mx="auto">
      <Menu isOpen={isOpen} onClose={onClose}>
        <Flex alignItems="center" justifyContent="space-between" width="100%">
          <MenuButton
            as={Button}
            onClick={onOpen}
            rightIcon={<ChevronDownIcon />}
            width="100%"
            textAlign="left"
            display="flex"
            justifyContent="space-between"
          >
            {selectedItem || "Select a user"}
          </MenuButton>
          {selectedItem && (
            <IconButton
              icon={<CloseIcon />}
              size="sm"
              onClick={clearSelectedItem}
              aria-label="Clear selected item"
              variant="ghost"
              ml={2}
            />
          )}
        </Flex>

        <MenuList
          maxHeight="400px" // Set a max height for the dropdown list
          overflowY="auto" // Make the dropdown scrollable when the list exceeds the max height
        >
          <Box p={2}>
            <InputGroup>
              <Input
                ref={inputRef}
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                autoFocus
                onKeyDown={handleKeyDown} // Capture keyboard events
              />
              {searchQuery && (
                <InputRightElement>
                  <IconButton
                    size="sm"
                    icon={<CloseIcon />}
                    onClick={clearSearch}
                    aria-label="Clear search"
                    variant="ghost"
                    tabIndex={-1} // Prevent tabbing to the icon
                  />
                </InputRightElement>
              )}
            </InputGroup>
          </Box>

          {filteredItems.length > 0 ? (
            filteredItems.map((item, index) => (
              <MenuItem key={index} onClick={() => handleSelectItem(item)}>
                {item}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No results found</MenuItem>
          )}
        </MenuList>
      </Menu>
    </Box>
  );
};

export default SearchableComboBox;

// src/redux/store.ts
import { configureStore, ThunkAction } from '@reduxjs/toolkit';
import { Action } from '@remix-run/router';
import dataReducer from './slices/dataSlice';

const store = configureStore({
  reducer: {
    data: dataReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;


export default store;
import React from 'react';
import Chart from 'react-google-charts';

type ChartProps = {
  chartData: any[];
  chartOptions: any;
  width?: string | number;
  height?: string | number;
};

const LineChart: React.FC<ChartProps> = ({chartData, chartOptions, width = '100%', height = '100%'}) => {
  return (
    <Chart
      chartType="LineChart"
      data={chartData}
      options={chartOptions}
      width="100%"
      height="400px"
    />

  );
};

export default LineChart;

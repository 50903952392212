import { Flex, Spinner, Text, Button, useBreakpointValue, useColorModeValue } from '@chakra-ui/react';
import Card from 'components/card/Card';
import { useCallback, useEffect, useMemo, useState } from 'react';
import LineChart from 'components/charts/LineChart';
import StrategyCalculator from 'utils/StrategyCalculator';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { useFontSize } from 'variables/charts';
import { globalStyles } from 'theme/styles';

export default function HistoricalValue(props: { showHistoricalData: boolean; }) {
  const { showHistoricalData, ...rest } = props;

  const { loading } = useSelector((state: RootState) => state.data);
  const textColor = useColorModeValue('secondaryGray.900', 'white');
  const stakeholders = useSelector((state: RootState) => state.data.stakeholders);
  const strategyMappingSettings = useSelector((state: RootState) => state.data.strategyMappingSettings);
  const selectedStrategyIds = useSelector((state: RootState) => state.data.selectedStrategyIds);
  const selectedDate = useSelector((state: RootState) => state.data.selectedDate);

  const currencyFormatter = useMemo(() => new Intl.NumberFormat('nl-BE', {
    style: 'currency',
    currency: 'EUR',
  }), []);

  const [lineChartData, setLineChartData] = useState<any>();

  const strategyCalculator = useMemo(() => new StrategyCalculator(strategyMappingSettings), [strategyMappingSettings]);

  // Updated getAllQuarters to use selectedDate
  const allQuarters = useMemo<string[]>(() => {
    const quarters = [];
    const today = selectedDate ? new Date(selectedDate) : new Date(); // Now using selectedDate instead of today

    const getQuarter = (month: number) => Math.floor(month / 3) + 1;

    // Check if the quarter is finished based on the selected date
    const isQuarterFinished = (quarter: number, year: number): boolean => {
      const quarterEndDates = [
        new Date(year, 2, 31),  // End of Q1: March 31
        new Date(year, 5, 30),  // End of Q2: June 30
        new Date(year, 8, 30),  // End of Q3: September 30
        new Date(year, 11, 31), // End of Q4: December 31
      ];
      return today > quarterEndDates[quarter - 1]; // Return true if selectedDate is after the quarter-end date
    };

    for (let i = 0; i < 12; i++) {
      const monthsAgo = today.getMonth() - i * 3;
      let year = today.getFullYear();
      let month = monthsAgo;

      while (month < 0) {
        month += 12;
        year -= 1;
      }

      while (month > 11) {
        month -= 12;
        year += 1;
      }

      const quarter = getQuarter(month);

      // Only push quarters that are finished
      if (isQuarterFinished(quarter, year)) {
        quarters.push(`Q${quarter} ${year}`);
      }
    }

    return quarters.reverse(); // Reverse to display the oldest first
  }, [selectedDate]);

  const [currentPage, setCurrentPage] = useState<number>(allQuarters.length - 2);  // Start with recent quarters

  const getEndDateForQuarter = (input: string): Date => {
    const quarter = input.split(" ")[0];
    const year = input.split(" ")[1];

    switch (quarter) {
      case "Q1":
        return new Date(+year, 3, 1);
      case "Q2":
        return new Date(+year, 6, 1);
      case "Q3":
        return new Date(+year, 9, 1);
      case "Q4":
        return new Date(+year + 1, 0, 1);
      default:
        return new Date();
    }
  };

  useEffect(() => {
    if (stakeholders && strategyMappingSettings) {
      const start = Math.max(0, currentPage - 2);  // Show 2 quarters before the current index
      const end = Math.min(allQuarters.length, currentPage + 2);  // Show 1 quarter after the current index

      const currentQuarters = allQuarters.slice(start, end);
      const dates = currentQuarters.map(range => getEndDateForQuarter(range));
      const actualValues = dates.map(endDate => strategyCalculator.calculateActualValue(stakeholders, selectedStrategyIds, endDate));

      const data: (string | number | object)[][] = [];
      data.push([
        'Period',
        'Actual Value',
        { role: 'annotation' },
      ]);

      currentQuarters.forEach((range, index) => {
        const value = actualValues[index];
        data.push([
          range,
          value,
          currencyFormatter.format(value),
        ]);
      });

      setLineChartData(data);
    }
  }, [stakeholders, selectedStrategyIds, strategyMappingSettings, strategyCalculator, currencyFormatter, currentPage, allQuarters]);

  const handlePrevious = () => {
    if (currentPage > 2) {  // Minimum page is 2, because we want at least 2 middle quarters
      setCurrentPage(currentPage - 1);  // Move one quarter back
    }
  };

  const handleNext = () => {
    const maxPage = allQuarters.length - 1;
    if (currentPage < maxPage - 1) {  // Prevent moving beyond the available quarters
      setCurrentPage(currentPage + 1);  // Move one quarter forward
    }
  };

  const fontSize = useBreakpointValue({ sm: 10, md: 11, xl: 13 });
  const LineChartOptionsConsumption = {
    chart: {
      title: 'Consumption Trends',
      subtitle: 'Product A',
      fontFamily: 'Source Sans Pro',
    },
    series: {
      0: {
        color: globalStyles.customColors.flamePea,
        annotations: {
          alwaysOutside: false,
          textStyle: {
            fontSize: fontSize,
            color: globalStyles.customColors.flamePea,
            fontName: 'Source Sans Pro',
          },
        },
      },
    },
    tooltip: { trigger: 'none' }, 
    legend: {
      position: 'bottom',
      alignment: 'start',
      textStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: 14,
        fontName: 'Source Sans Pro',
      },
    },
    hAxis: {
      titleTextStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: 14,
        italic: false,
      },
      textStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: useFontSize,
        italic: false,
        fontName: 'Source Sans Pro',
      },
      gridlines: {
        color: 'transparent',
      },
    },
    vAxis: {
      titleTextStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: 14,
        italic: false,
      },
      textStyle: {
        color: globalStyles.customColors.osloGray,
        fontSize: useFontSize,
        italic: false,
        fontName: 'Source Sans Pro',
      },
      format: '€#,##0.00',
      gridlines: {
        color: 'rgba(163, 174, 208, 0.3)',
      },
    },
    colors: ['#e2a754'],
    backgroundColor: {
      fill: 'transparent',
    },
    chartArea: {
      left: 85,
      top: 40,
      right: 20,
      bottom: 50,
    },
  };


  return (
    <Card p="20px" alignItems="center" flexDirection="column" w="100%" h="100%" {...rest}>
      <Flex align="center" w="100%" px="15px" py="10px">
        <Text me="auto" color={textColor} fontSize="xl" fontWeight="700" lineHeight="100%">
          Historical Value
        </Text>
      </Flex>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <Flex align="center" w="100%">
            <LineChart chartData={lineChartData} chartOptions={LineChartOptionsConsumption} />
          </Flex>
          <Flex mb="10px">
            <Button onClick={handlePrevious} isDisabled={currentPage === 2}>  {/* Disable if at first page */}
              Previous
            </Button>
            <Button onClick={handleNext} ml="auto" isDisabled={currentPage >= allQuarters.length - 2}>  {/* Disable if at the last page */}
              Next
            </Button>
          </Flex>
        </>
      )}
    </Card>
  );
}

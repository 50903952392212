// src/redux/slices/dataSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getDateFromLocalStorage,
  getNumberArrayFromLocalStorage,
  getStringArrayFromLocalStorage
} from "../../utils/localStorageParsing";
import { DataState, Entity, SecurityTypeMapping, Stakeholder, TranslationMapping, User, UserEmails } from "./types";
import {
  fetchAllEmailsofUsers,
  fetchEntitiesByEmail,
  fetchSecurityTypeMappingSettings,
  fetchStakeholders,
  fetchStrategyMappingSettings,
  fetchTranslationMappingSettings,
  fetchusersByEmail
} from "./thunks";


const initialActualState = ['All strategies']

const initialState: DataState = {
  users: [],
  usersByOriginalEmail: [],
  entitiesbyEmail: [],
  loading: false,
  error: null,
  selectedEntityIds: [],
  strategyMappingSettings: { values: [] },
  stakeholders: [],
  selectedStrategyIds: getNumberArrayFromLocalStorage("selectedStrategyIds"),
  portfolioDetailsValues: [],
  selectedDate: getDateFromLocalStorage("selectedDate").toDateString(),
  translationMappingSettings: { transactions: [] },
  securityTypeMappingSettings: { securityTypes: [] },
  actualValueState: getStringArrayFromLocalStorage("actualValueState", initialActualState),
  emailsToImpersonate: [],
  selectedEmail: "",
  originalEmail: ""
};

const dataSlice = createSlice({
  name: "data",
  initialState,
  reducers: {
    setSelectedEntityIds(state: DataState, action: PayloadAction<number[]>) {
      state.selectedEntityIds = Array.from(new Set(action.payload));
    },
    setSelectedStrategyids(state, action: PayloadAction<number[]>) {
      state.selectedStrategyIds = Array.from(new Set(action.payload));
      localStorage.setItem("selectedStrategyIds", JSON.stringify(state.selectedStrategyIds));
    },
    resetSelectedEntityIds: (state: DataState) => {
      state.selectedEntityIds = state.entitiesbyEmail
        .slice()
        .sort((a, b) => a.entityName.localeCompare(b.entityName))
        .map(e => Number(e.entityId))
    },
    resetSelectedStrategyIds: (state: DataState) => {
      state.selectedStrategyIds = Array.from(
        new Set(
          state.strategyMappingSettings.values
            .flatMap((x) => x.combined)
            .flatMap((x) => x.values)
            .map((x) => x.entityId)
        )
      );
      state.actualValueState = initialActualState
      localStorage.setItem("selectedStrategyIds", JSON.stringify(state.selectedStrategyIds));
    },
    setPortfolioDetails(
      state,
      action: PayloadAction<(string | number | object)[][]>
    ) {
      state.portfolioDetailsValues = action.payload;
    },
    setSelectedDate(state, action: PayloadAction<string>) {
      let date = new Date(action.payload);
      state.selectedDate = date.toDateString();
      localStorage.setItem("selectedDate", state.selectedDate);
    },
    setActualValueState(state: DataState, action: PayloadAction<string[]>) {
      state.actualValueState = action.payload;
      localStorage.setItem("actualValueState", JSON.stringify(state.actualValueState));
    },
    resetActualValueState(state: DataState) {
      state.actualValueState = [...initialActualState];
      localStorage.setItem("actualValueState", JSON.stringify(state.actualValueState));
    },
    setSelectedEmail(state, action: PayloadAction<string>) {
      state.selectedEmail = action.payload;
    },
    setOriginalEmail(state, action: PayloadAction<string>) {
      state.originalEmail = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchusersByEmail.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchusersByEmail.fulfilled,
        (state: DataState, action: PayloadAction<User[]>) => {
          state.loading = false;
          if (state.selectedEmail === state.originalEmail) {
            state.usersByOriginalEmail = action.payload;
          }
          state.users = action.payload;
        }
      )
      .addCase(fetchusersByEmail.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch users";
      })
      .addCase(fetchEntitiesByEmail.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchEntitiesByEmail.fulfilled,
        (state: DataState, action: PayloadAction<Entity[]>) => {
          state.loading = false;
          state.entitiesbyEmail = action.payload;
        }
      )
      .addCase(fetchEntitiesByEmail.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch entities";
      })
      .addCase(fetchStakeholders.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchStakeholders.fulfilled,
        (state: DataState, action: PayloadAction<Stakeholder[]>) => {
          state.loading = false;
          state.stakeholders = action.payload;
        }
      )
      .addCase(fetchStakeholders.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error = action.error.message || "Failed to fetch stakeholders";
      })
      .addCase(fetchStrategyMappingSettings.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchStrategyMappingSettings.fulfilled,
        (state: DataState, action) => {
          state.loading = false;
          state.strategyMappingSettings = action.payload;
          if (state.selectedStrategyIds.length === 0) {
            state.selectedStrategyIds = state.strategyMappingSettings.values
              .flatMap((x) => x.combined)
              .flatMap((x) => x.values)
              .map((x) => x.entityId);
            localStorage.setItem("selectedStrategyIds", JSON.stringify(state.selectedStrategyIds));
          }
        }
      )
      .addCase(fetchStrategyMappingSettings.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch translation mappings";
      })
      .addCase(fetchTranslationMappingSettings.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchTranslationMappingSettings.fulfilled,
        (state: DataState, action: PayloadAction<TranslationMapping>) => {
          state.loading = false;
          state.translationMappingSettings = action.payload;
        }
      )
      .addCase(fetchTranslationMappingSettings.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch translation mappings";
      })
      .addCase(fetchSecurityTypeMappingSettings.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSecurityTypeMappingSettings.fulfilled,
        (state: DataState, action: PayloadAction<SecurityTypeMapping>) => {
          state.loading = false;
          state.securityTypeMappingSettings = action.payload;
        }
      )
      .addCase(fetchSecurityTypeMappingSettings.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch security type mappings";
      })
      .addCase(fetchAllEmailsofUsers.pending, (state: DataState) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAllEmailsofUsers.fulfilled,
        (state: DataState, action: PayloadAction<UserEmails[]>) => {
          state.loading = false;
          state.emailsToImpersonate = action.payload;
        }
      )
      .addCase(fetchAllEmailsofUsers.rejected, (state: DataState, action) => {
        state.loading = false;
        state.error =
          action.error.message || "Failed to fetch admins";
      });
  },
});

export const {
  setSelectedEntityIds,
  resetSelectedEntityIds,
  setSelectedStrategyids,
  resetSelectedStrategyIds,
  setPortfolioDetails,
  setSelectedDate,
  setSelectedEmail,
  setOriginalEmail,
  setActualValueState,
  resetActualValueState,
} = dataSlice.actions;
export default dataSlice.reducer;

import { Icon } from '@chakra-ui/react';
import { MdHome } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Reporting from 'views/admin/reporting';

// Auth Imports
import { IoMdDocument } from 'react-icons/io';

const routes = [
	{
		name: 'Dashboard',
		layout: '',
		path: '/',
		icon: <Icon as={MdHome} width='20px' height='20px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Detailed Reporting',
		layout: '',
		path: '/reporting',
		icon: <Icon as={IoMdDocument} width='20px' height='20px' color='inherit' />,
		component: Reporting
	}
];

export default routes;

import { useRef } from "react";
import { useMutation, gql } from "@apollo/client";
import { MenuItem, Text } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadCSV($file: Upload!) {
    uploadCsvFile(file: $file) {
      success
      message
    }
  }
`;

const FileUpload = () => {
  const [uploadFile] = useMutation(UPLOAD_FILE_MUTATION);

  const toast = useToast();

  const handleFileChange = (event: any) => {
    handleSubmit(event.target.files[0]);
    event.target.value = "";
  };

  const handleSubmit = async (file: File) => {
    if (!file) return;

    toast({
      title: "Processing file",
      description: "We are processing your file.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });

    try {
      const { data } = await uploadFile({ variables: { file: file } });
      console.log(data.uploadCsvFile);

      toast({
        title: data.uploadCsvFile.success ? "File uploaded" : "Upload failed",
        description: data.uploadCsvFile.message,
        status: data.uploadCsvFile.success ? "success" : "error", 
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Error uploading file:", error);

      toast({
        title: "Upload failed",
        description: `${error}`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <div>
      <MenuItem onClick={handleFileUploadClick}>
        <Text fontSize="sm">Upload CSV</Text>
      </MenuItem>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={handleFileChange}
      />
    </div>
  );
};

export default FileUpload;

import { useEffect, useState } from 'react';

export const useDomElementListener = (elementId: string,) => {
  const [entry, setEntry] = useState<ResizeObserverEntry>();

  useEffect(() => {
    const element = document.getElementById(elementId);

    if (!element) {
      console.warn(`Element with id "${elementId}" not found.`);
      return;
    }

    const resizeObserver = new ResizeObserver((entries) => {
      if (entries.length === 0) return;
      setEntry(entries[0]);
    });

    resizeObserver.observe(element);

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
    };
  }, [elementId]);

  return entry

};

import { mode } from "@chakra-ui/theme-tools";

const flamePea = "#d35e31";
const flamePeaLight = "#dc7e5a";
const grannySmith = "#939ea0";
const spannishGreen = "#829488";
const spannishGreenLight = "#9ba9a0";
const bermudaGray = "#7494AC";
const bermudaGrayLight = "#9eb4c5";
const mongoose = "#b8a080";
const outerSpace = "#253532";
const athensGray = "#e9ecef";
const loblolly = "#c6ced4";
const osloGray = "#818486";

export const globalStyles = {
  customColors: {
    all: [
      flamePea,
      spannishGreen,
      bermudaGray,
      mongoose,
      flamePeaLight,
      spannishGreenLight,
      bermudaGrayLight,
      osloGray
    ],
    flamePea,
    grannySmith,
    spannishGreen,
    bermudaGray,
    mongoose,
    outerSpace,
    athensGray,
    loblolly,
    osloGray,
  },
  colors: {
    brand: {
      100: flamePea,
      200: flamePea,
      300: flamePea,
      400: flamePea,
      500: flamePea,
      600: flamePea,
      700: flamePea,
      800: flamePea,
      900: flamePea,
    },
    brandScheme: {
      100: flamePea,
      200: flamePea,
      300: flamePea,
      400: flamePea,
      500: flamePea,
      600: flamePea,
      700: flamePea,
      800: flamePea,
      900: flamePea,
    },
    brandTabs: {
      100: flamePea,
      200: flamePea,
      300: flamePea,
      400: flamePea,
      500: flamePea,
      600: flamePea,
      700: flamePea,
      800: flamePea,
      900: flamePea,
    },
    secondaryGray: {
      100: athensGray,
      200: athensGray,
      300: athensGray,
      400: loblolly,
      500: loblolly,
      600: osloGray,
      700: osloGray,
      800: outerSpace,
      900: outerSpace,
    },
    red: {
      100: "#FEEFEE",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
    },
  },
  styles: {
    global: (props: any) => ({
      body: {
        overflowX: "hidden",
        bg: mode("secondaryGray.300", "navy.900")(props),
        fontFamily: "Source Sans Pro",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "gray.700",
      },
      html: {
        fontFamily: "Source Sans Pro",
      },
    }),
  },
};

import { Box, SimpleGrid } from "@chakra-ui/react";
import ActualValueChart from "views/admin/default/components/ActualValueChart";
import ReturnValue from "../default/components/ReturnChart";
import TransactionDetails from "./components/TransactionDetails";
import SHLOverview from "./components/SHLOverview";

export default function Overview() {
  return (
    <Box>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap="20px" mb="20px">
        <ActualValueChart />
        <ReturnValue showHistoricalData={true} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <TransactionDetails showHistoricalData={true} />
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap="20px" mb="20px">
        <SHLOverview showHistoricalData={true} />
      </SimpleGrid>
    </Box>
  );
}

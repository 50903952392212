// Chakra imports
import { Flex } from '@chakra-ui/react';

// Custom components
import { HSeparator } from 'components/separator/Separator';
import mainLogo from 'assets/img/logo_new_black.png';
import { Image } from '@chakra-ui/react'

export function SidebarBrand() {
	return (
		<Flex alignItems='center' flexDirection='column' >
			<Image src={mainLogo} alt="Buysse & Partners" height='70px' mb={5} me={{ base: '0px !important', lg: '16px !important' }}/>
			<HSeparator mb='20px' w={300} me={{ base: '0px !important', 'lg': '16px !important' }}/>
		</Flex>
	);
}

export default SidebarBrand;

import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/react-table";
import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";
import StrategyCalculator from "utils/StrategyCalculator";
import { PagedTable } from "components/dataDisplay/PagedTable";
import { StakeholderLoanCalculationStepWithLoan } from "redux/slices/types";

type RowObj = {
  strategy: string;
  investment: string;
  transaction: string;
  date: Date;
  interest: number;
  totalOutstandingAmountPre: number;
  totalOutstandingAmountPost: number;
  grossAmountTransaction: number;
  rv: number;
  rvEuro: number;
  netAmountTransaction: number;
  cashFlow: number;
  compartiment: string;
};

export default function SHLOverview(props: { showHistoricalData: boolean }) {
  const { showHistoricalData } = props;

  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const strategyMappingSettings = useSelector(
    (state: RootState) => state.data.strategyMappingSettings
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const translations = useSelector((state: RootState) =>
    state.data.translationMappingSettings.transactions.filter(
      (x) => x.type === "SHL"
    )
  );

  const currencyFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        style: "currency",
        currency: "EUR",
      }),
    []
  );

  const dateFormatter = useMemo(() => new Intl.DateTimeFormat("nl-BE", {}), []);
  const numberDecimalFormatter = useMemo(
    () =>
      new Intl.NumberFormat("nl-BE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
    []
  );

  const columnHelper = createColumnHelper<RowObj>();
  const strategyCalculator = useMemo(
    () => new StrategyCalculator(strategyMappingSettings),
    [strategyMappingSettings]
  );

  const [calculationDate, setCalculationDate] = useState<Date>(new Date());

  useEffect(() => {
    if (showHistoricalData) {
      const calculationDate = selectedDate
        ? new Date(selectedDate)
        : new Date();
      setCalculationDate(calculationDate);
    } else {
      setCalculationDate(new Date());
    }
  }, [selectedDate, showHistoricalData]);

  const transactionRows = useMemo<RowObj[]>(() => {
    const rows: RowObj[] = [];

    selectedStrategyIds.forEach((strategyId) => {
      const strategyName = strategyCalculator.getStrategyNameById(strategyId);
      const investment =
        strategyCalculator.getCombinedNameByStrategyId(strategyId);
      const compartiment = strategyCalculator.getDetailedNameByStrategyId(strategyId);

      stakeholders.forEach((st) => {
        // console.log(`Actual value calculation Stakeholder ${st.ownerId}:`);

        let allSteps: StakeholderLoanCalculationStepWithLoan[] = [];

        st.loans.forEach((loan) => {
          if (strategyId !== loan.strategyId) {
            return;
          }

          let calculationsWithLoan: StakeholderLoanCalculationStepWithLoan[] =
            [];

          const startLoanExists =
            allSteps.filter(
              (x) => x.loan.strategyId === strategyId && x.type === "Start Loan"
            )?.length > 0;

          if (!startLoanExists) {
            const initialLoanState: StakeholderLoanCalculationStepWithLoan = {
              amount: 0,
              date: loan.startDate,
              grossInterest: loan.principalAmount,
              netInterest: loan.principalAmount,
              outstandingNetInterest: 0,
              outstandingPrincipalAmount: loan.principalAmount,
              totalGrossInterest: 0,
              totalNetInterest: 0,
              totalOutstandingAmount: loan.principalAmount,
              totalWithHoldingTax: null,
              type: "Start Loan",
              withHoldingTax: null,
              loan: loan,
            };

            calculationsWithLoan.push(initialLoanState);
          }

          calculationsWithLoan.push(
            ...loan.loanCalculations
              ?.filter(
                (x) =>
                  new Date(x.validFrom).getTime() < calculationDate.getTime()
              )
              .reduce((acc, calc) => {
                const calculationStepWithLoan = calc.calculationSteps.map(
                  (calculation) => ({
                    ...calculation,
                    loan: loan,
                  })
                );
                return acc.concat(calculationStepWithLoan);
              }, [] as StakeholderLoanCalculationStepWithLoan[])
          );

          allSteps.push(...calculationsWithLoan);
        });

        const sortedSteps = [...allSteps].sort(
          (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
        );

        sortedSteps.forEach((step, index) => {
          let grossAmountTransaction = 0;
          let netInterest = 0;
          let totalOutstandingAmountPost = 0;
          let cashFlow = 0;

          switch (step.type) {
            case "Start Loan":
              grossAmountTransaction = step.grossInterest;
              netInterest = step.netInterest;
              totalOutstandingAmountPost = step.totalOutstandingAmount;
              cashFlow = step.grossInterest * -1;
              break;
            case "ACCRUAL":
            case "PRO_RATA":
              grossAmountTransaction = step.grossInterest;
              netInterest = step.netInterest;
              totalOutstandingAmountPost = step.totalOutstandingAmount;
              break;
            case "PAYMENT":
              grossAmountTransaction = step.grossInterest * -1;
              netInterest = step.netInterest * -1;
              totalOutstandingAmountPost = step.totalOutstandingAmount;
              cashFlow = step.grossInterest;
              break;
          }

          rows.push({
            strategy: strategyName,
            investment: investment,
            transaction: step.type,
            date: new Date(step.date),
            interest: step.loan.interestRate,
            totalOutstandingAmountPre:
              index > 0 ? sortedSteps[index - 1].totalOutstandingAmount : null,
            totalOutstandingAmountPost: totalOutstandingAmountPost,
            grossAmountTransaction: grossAmountTransaction,
            rv: step.withHoldingTax !== null ? +step.withHoldingTax : null,
            rvEuro:
              step.totalWithHoldingTax !== null
                ? +step.totalWithHoldingTax
                : null,
            netAmountTransaction: netInterest,
            cashFlow: cashFlow,
            compartiment: compartiment
          });
        });
      });
    });

    return rows.sort((a, b) => b.date.getTime() - a.date.getTime());
  }, [calculationDate, selectedStrategyIds, stakeholders, strategyCalculator]);

  const translateTransaction = (transaction: string) => {
    return (
      translations.find((x) => x.original === transaction)?.translated ?? transaction
    );
  };

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const columns = [
    columnHelper.accessor("strategy", {
      id: "strategy",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Strategy
        </Text>
      ),
      cell: (info: any) => (
        <Flex align="center">
          <Text color={textColor} fontSize="sm" fontWeight="400">
            {info.getValue()}
          </Text>
        </Flex>
      ),
    }),
    columnHelper.accessor("investment", {
      id: "investment",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Investment
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("compartiment", {
      id: "compartiment",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Compartiment
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue()}
        </Text>
      ),
    }),
    columnHelper.accessor("transaction", {
      id: "transaction",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Transaction
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {translateTransaction(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("date", {
      id: "date",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Date
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {dateFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("cashFlow", {
      id: "cashFlow",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Cashflow
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("interest", {
      id: "interest",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Interest (%)
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {numberDecimalFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("totalOutstandingAmountPre", {
      id: "totalOutstandingAmountPre",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Total Outstanding Amount (Pre Transaction)
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null
            ? currencyFormatter.format(info.getValue())
            : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("grossAmountTransaction", {
      id: "grossAmountTransaction",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Gross Amount Transaction
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("rv", {
      id: "rv",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          RV (%)
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null
            ? numberDecimalFormatter.format(info.getValue())
            : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("rvEuro", {
      id: "rvEuro",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          RV (EURO)
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {info.getValue() != null
            ? currencyFormatter.format(info.getValue())
            : "-"}
        </Text>
      ),
    }),
    columnHelper.accessor("netAmountTransaction", {
      id: "netAmountTransaction",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Net Amount Transaction
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
    columnHelper.accessor("totalOutstandingAmountPost", {
      id: "totalOutstandingAmountPost",
      header: () => (
        <Text fontSize={{ sm: "10px", lg: "12px" }} color="gray.400">
          Total Outstanding Amount (Post Transaction)
        </Text>
      ),
      cell: (info: any) => (
        <Text color={textColor} fontSize="sm" fontWeight="400">
          {currencyFormatter.format(info.getValue())}
        </Text>
      ),
    }),
  ];

  return (
    <PagedTable title="SHL Overview" columns={columns} data={transactionRows}/>
  );
}

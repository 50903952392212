// components/DatePickerSelect.tsx
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, useDisclosure } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedDate } from '../../redux/slices/dataSlice';
import { ChevronDownIcon } from "@chakra-ui/icons";
import { RootState } from "../../redux/store";

const DatePickerSelect: React.FC = () => {
  const { isOpen, onToggle, onClose } = useDisclosure();
  // Use onToggle to toggle isOpen state
  const selectedDate = useSelector(
    (state: RootState) => state.data.selectedDate
  );
  const dispatch = useDispatch();
  const formatDate = (date: Date | string | null) => {
    return date ? new Date(date).toLocaleDateString('en-GB') : '';
  };
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const dateString = date.toDateString();
      dispatch(setSelectedDate(dateString));
      onClose();
    }
  };

  const handleButtonClick = () => {
    if (isOpen) {
      onClose();
    } else {
      onToggle();
    }
  };

  return (
    <>
      <Button
        rightIcon={<ChevronDownIcon/>}
        onClick={handleButtonClick} sx={{ fontSize: 14, fontWeight: 'bold', position: 'relative' }}
      >
        {selectedDate ? formatDate(selectedDate) : 'Pick date'}
      </Button>
      {isOpen && (
        <div style={{ position: 'absolute', zIndex: 1 }}>
          <DatePicker
            selected={selectedDate ? new Date(selectedDate) : new Date()}
            onClickOutside={onClose}
            onChange={handleDateChange}
            inline
            todayButton="Today"
            clearButtonTitle='Clear'
            clearButtonClassName='hello'
            maxDate={new Date()}
          />
        </div>
      )}
    </>
  );
};

export default DatePickerSelect;

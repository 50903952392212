import { gql } from "@apollo/client";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "../../index";
import {
    Entity,
    SecurityTypeMapping,
    Stakeholder,
    StrategyMapping,
    TranslationMapping,
    User,
    UserEmails
} from "./types";

const GET_USERSBYEMAIL = gql`
    query usersbyEmail($email: String!) {
        usersByEmail(email: $email) {
            id
            name
            role
            entityName
            entityId
            isAdmin
        }
    }
`;

const GET_ENTITIESBYEMAIL = gql`
    query entitiesbyEmail($userIds: [Int!]!, $role: String!) {
        entitiesbyEmail(userIds: $userIds, role: $role) {
            entityId
            firstName
            name
            entityName
            entityType
            shareStrategyIds
            loanStrategyIds
        }
    }
`;

const GET_STRATEGYMAPPINGSETTINGS = gql`
    query strategyMappingSettings {
        strategyMappingSettings {
            values {
                strategy
                combined {
                    name
                    values {
                        entityId
                        investment
                        type
                        loanType
                        prices {
                            type
                            price
                            calculationDate
                        }
                    }
                }
            }
        }
    }
`;

const GET_TRANSLATIONMAPPINGSETTINGS = gql`
    query translationMappingSettings {
        translationMappingSettings {
            transactions {
                type
                original
                translated
            }
        }
    }
`;

const GET_SECURITYTYPEMAPPINGSETTINGS = gql`
    query securityTypeMappingSettings {
        securityTypeMappingSettings {
            securityTypes {
                description
                strategyIds
                mappings {
                    original
                    translated
                }
            }
        }
    }
`;

const GET_STAKEHOLDERS = gql`
    query stakeholders($ids: [Int!]!) {
        stakeholders(entityIds: $ids) {
            ownerId
            partitionKey
            commitments {
                entityState
                id
                strategyId
                subscribedShareCapital
                subscribedShareEquity
                subscribedSharePremium
                totalCommittedAmount
                totalLentAmount
                totalOutstandingCommittedAmount
                totalPaidUpAmount
                totalPaidUpCapital
                totalPaidUpShareCapital
                totalPaidUpShareEquity
                totalPaidUpSharePremium
                totalSubscribedAmount
                holder {
                    committedAmount
                    endDate
                    lentAmount
                    outstandingCommittedAmount
                    ownerFirstName
                    ownerId
                    ownerName
                    paidUpCapital
                    paidUpCommitment
                    paidUpEquity
                    paidUpPremium
                    percentagePaidUpCommitment
                    startDate
                    subscribedAmount
                    subscribedCapital
                    subscribedEquity
                    subscribedPremium
                }
            }
            loans {
                loanId
                strategyId
                principalAmount
                startDate
                interestRate
                accrualPeriod
                witholdingTax
                loanCalculations {
                    id
                    loanId
                    outstandingNetInterest
                    outstandingPrincipalAmount
                    partitionKey
                    totalGrossInterest
                    totalNetInterest
                    totalOutstandingAmount
                    totalWithHoldingTax
                    validFrom
                    calculationSteps {
                        amount
                        date
                        grossInterest
                        netInterest
                        outstandingNetInterest
                        outstandingPrincipalAmount
                        totalGrossInterest
                        totalNetInterest
                        totalOutstandingAmount
                        totalWithHoldingTax
                        type
                        withHoldingTax
                    }
                }
            }
            shares {
                shareId
                strategyId
                shareTransactions {
                    actionType
                    assetType
                    id
                    partitionKey
                    shareCapital
                    shareCapitalPaidUp
                    shareCapitalSubscribed
                    shareCapitalUnpaid
                    shareId
                    sharePremium
                    sharePremiumPaidUp
                    sharePremiumSubscribed
                    sharePremiumUnpaid
                    shareValue
                    strategyId
                    transactionDate
                    description
                    transactionId
                    type
                    transferDirection
                    holder {
                        amount
                        numberOfSecurities
                        ownerFirstName
                        ownerId
                        ownerName
                        securityClass
                        grossDividend
                        sharePremiumSubscribed
                        sharePremiumPayouts
                        sharePremiumUnpaid
                        sharePremiumPaidUp
                        capitalEquitySubscribed
                        capitalEquityPayouts
                        capitalEquityUnpaid
                        capitalEquityPaidUp
                        purchasePrice
                        totalPaidUpAmount
                        totalAmountToPay
                        shareCapitalPaidUp
                        shareCapitalUnpaid
                        capitalPayouts
                    }
                }
            }
            certificates {
                amount
                ownerId
                secClassName
                strategyId
            }
            loanShares {
                shareId
                strategyId
                shareTransactions {
                    actionType
                    assetType
                    id
                    partitionKey
                    shareCapital
                    shareCapitalPaidUp
                    shareCapitalSubscribed
                    shareCapitalUnpaid
                    shareId
                    sharePremium
                    sharePremiumPaidUp
                    sharePremiumSubscribed
                    sharePremiumUnpaid
                    shareValue
                    strategyId
                    transactionDate
                    description
                    transactionId
                    type
                    holder {
                        amount
                        numberOfSecurities
                        ownerFirstName
                        ownerId
                        ownerName
                        securityClass
                        grossDividend
                        sharePremiumSubscribed
                        sharePremiumPayouts
                        sharePremiumUnpaid
                        sharePremiumPaidUp
                        capitalEquitySubscribed
                        capitalEquityPayouts
                        capitalEquityUnpaid
                        capitalEquityPaidUp
                        purchasePrice
                    }
                }
            }
        }
    }
`;

const GET_ALLEMAILSOFUSERS = gql`
    query allEmailsofUsers {
        allEmailsofUsers {
            id
            userId
            email
        }
    }
`;


export const fetchusersByEmail = createAsyncThunk(
  "data/fetchusersByEmail",
  async (email: string) => {
    const response = await client.query({
      query: GET_USERSBYEMAIL,
      variables: { email },
    });
    return response.data.usersByEmail as User[];
  }
);

export const fetchEntitiesByEmail = createAsyncThunk(
  "data/fetchEntitiesByEmail",
  async ({ userIds, role }: { userIds: string[]; role: string }) => {
    const response = await client.query({
      query: GET_ENTITIESBYEMAIL,
      variables: { userIds, role },
    });
    return response.data.entitiesbyEmail as Entity[];
  }
);

export const fetchStrategyMappingSettings = createAsyncThunk(
  "data/fetchStrategyMappingSettings",
  async () => {
    const response = await client.query({
      query: GET_STRATEGYMAPPINGSETTINGS,
    });
    return response.data.strategyMappingSettings as StrategyMapping;
  }
);

export const fetchTranslationMappingSettings = createAsyncThunk(
  "data/fetchTranslationMappingSettings",
  async () => {
    const response = await client.query({
      query: GET_TRANSLATIONMAPPINGSETTINGS,
    });
    return response.data.translationMappingSettings as TranslationMapping;
  }
);

export const fetchSecurityTypeMappingSettings = createAsyncThunk(
  "data/fetchSecurityTypeMappingSettings",
  async () => {
    const response = await client.query({
      query: GET_SECURITYTYPEMAPPINGSETTINGS,
    });
    return response.data.securityTypeMappingSettings as SecurityTypeMapping;
  }
);

export const fetchStakeholders = createAsyncThunk(
  "data/fetchStakeholders",
  async ({ ownerIds }: { ownerIds: number[] }) => {
    const response = await client.query({
      query: GET_STAKEHOLDERS,
      variables: { ids: ownerIds },
    });
    return response.data.stakeholders as Stakeholder[];
  }
);
export const fetchAllEmailsofUsers = createAsyncThunk(
  "data/fetchAllEmailsofUsers",
  async () => {
    const response = await client.query({
      query: GET_ALLEMAILSOFUSERS,
    });
    return response.data.allEmailsofUsers as UserEmails[];
  }
);

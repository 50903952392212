import { useEffect } from 'react';
import { useBreakpointValue } from '@chakra-ui/react';
import { globalStyles } from 'theme/styles';

// Custom Hook to get responsive font size
export function useFontSize() {
  // useBreakpointValue should be used directly inside a React component or custom Hook
  const fontSize = useBreakpointValue({ sm: "200px", md: "200px", xl: "200px" });

  // useEffect cannot be used directly in a regular JavaScript function
  useEffect(() => {
    // Perform any side effects related to fontSize here if needed
    console.log(`Current fontSize: ${fontSize}`);
  }, [fontSize]); // Ensure useEffect runs whenever fontSize changes

  return fontSize; // Return the fontSize from the Hook
}


export const barChartDataDailyTraffic = [
	{
		name: 'Daily Traffic',
		data: [ 20, 30, 40, 20, 45, 50, 30 ]
	}
];

export const barChartOptionsDailyTraffic: any = {
	chart: {
		toolbar: {
			show: false
		}
	},
	tooltip: {
		style: {
			fontSize: '12px',
			fontFamily: 'Source Sans Pro'
		},
		onDatasetHover: {
			style: {
				fontSize: '12px',
			fontFamily: 'Source Sans Pro'
			}
		},
		theme: 'dark'
	},
	xaxis: {
		categories: [ '00', '04', '08', '12', '14', '16', '18' ],
		show: false,
		labels: {
			show: true,
			style: {
				colors: '#e2a754',
				fontSize: '14px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: false,
		color: 'black',
		labels: {
			show: true,
			style: {
				colors: '#548fe2',
				fontSize: '14px'
			}
		}
	},
	grid: {
		show: false,
		strokeDashArray: 5,
		yaxis: {
			lines: {
				show: true
			}
		},
		xaxis: {
			lines: {
				show: false
			}
		}
	},
	fill: {
		type: 'gradient',
		gradient: {
			type: 'vertical',
			shadeIntensity: 1,
			opacityFrom: 0.7,
			opacityTo: 0.9,
			colorStops: [
				[
					{
						offset: 0,
						color: '#e2a754',
						opacity: 1
					},
					{
						offset: 100,
						color: '#c75e13',
						opacity: 0.28
					}
				]
			]
		}
	},
	dataLabels: {
		enabled: false
	},
	plotOptions: {
		bar: {
			borderRadius: 10,
			columnWidth: '40px'
		}
	}
};

export const barChartDataUserActivity = [
	{
		name: 'PRODUCT A',
		data: [ 70, 55, 41, 67, 22, 43 ]
	},
	{
		name: 'PRODUCT B',
		data: [ 90, 70, 60, 50, 80, 90 ]
	}
];

// User Activity

export const barChartOptionsUserActivity = {
	chart: {
		stacked: true,
		toolbar: {
			show: false
		}
	},
	tooltip: {
		style: {
			fontSize: '12px'
		},
		onDatasetHover: {
			style: {
				fontSize: '12px'
			}
		},
		theme: 'dark'
	},
	xaxis: {
		categories: [ 'S', 'M', 'T', 'W', 'T', 'F' ],
		show: false,
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '14px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: true,
		color: 'black',
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '14px',
				fontWeight: '500'
			}
		}
	},

	grid: {
		borderColor: 'rgba(163, 174, 208, 0.3)',
		show: true,
		yaxis: {
			lines: {
				show: true,
				opacity: 0.5
			}
		},
		row: {
			opacity: 0.5
		},
		xaxis: {
			lines: {
				show: false
			}
		}
	},
	fill: {
		type: 'solid',
		colors: [ '#5E37FF', '#548fe2' ]
	},
	legend: {
		show: false
	},
	colors: [ '#5E37FF', '#548fe2' ],
	dataLabels: {
		enabled: false
	},
	plotOptions: {
		bar: {
			borderRadius: 10,
			columnWidth: '30px'
		}
	}
};
// Consumption Users Reports

export const barChartDataConsumption = [
	{
		name: 'PRODUCT A',
		data: [ -21613123, -2706496, 23141661, 4235034 ]
	}
];

export const barChartOptionsConsumption: any = {
	chart: {
		stacked: true,
		toolbar: {
			show: false
		},
		fontFamily: 'Source Sans Pro'
	},
	tooltip: {
		style: {
			fontSize: '12px',
			fontFamily: 'Source Sans Pro'
		},
		onDatasetHover: {
			style: {
				fontSize: '12px',
				fontFamily: 'Source Sans Pro'
			}
		},
		theme: 'dark'
	},
	xaxis: {
		categories: ['Instapwaarde', 'Ontvangen uitkeringen', 'Unrealised value', 'Totale rendement'],
		show: true,
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: true
		},
		axisTicks: {
			show: true
		}
	},
	yaxis: {
		show: true,
		color: 'black',
		labels: {
			show: true,
			style: {
				colors: '#A3AED0',
				fontSize: '14px',
				fontWeight: '500'
			},
			formatter: function (value: any) {
				return '€ ' + value.toLocaleString('de-DE', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			},
		},
		min: -25000000,
		max: 25000000,
		tickAmount: 5
	},
	grid: {
		borderColor: 'rgba(163, 174, 208, 0.3)',
		show: true,
		yaxis: {
			lines: {
				show: true,
				opacity: 0.5
			}
		},
		row: {
			opacity: 0.5
		},
		xaxis: {
			lines: {
				show: false
			}
		}
	},
	fill: {
		type: 'solid',
		colors: ['#3c4b78', '#E1E9F8']
	},
	legend: {
		show: true
	},
	colors: ['#3c4b78', '#E1E9F8'],
	dataLabels: {
		enabled: false
	},
	plotOptions: {
		bar: {
			columnWidth: '40px'
		}
	},
	responsive: [
		{
			breakpoint: 450,
			options: {
				chart: {
					width: 330,
					height: 350,
					fontFamily: 'Source Sans Pro'
				},
				xaxis: {
					labels: {
						style: {
							fontSize: '10px',
						},
					}
				},
				yaxis: {
					labels: {
						style: {
							fontSize: '10px',
						},
						formatter: function (value: any) {
							return '€ ' + value.toLocaleString('nl-BE', {
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							});
						},
					},
					min: -25000000,
					max: 25000000,
					tickAmount: 5
				}
			}
		}
	]
};



  



// Total Spent Default

export const lineChartDataTotalSpent = [
	{
		name: 'Revenue',
		data: [ 50, 64, 48, 66, 49, 68 ]
	},
	{
		name: 'Profit',
		data: [ 30, 40, 24, 46, 20, 46 ]
	}
];

export const lineChartOptionsTotalSpent: any = {
	chart: {
		toolbar: {
			show: false
		},
		dropShadow: {
			enabled: true,
			top: 13,
			left: 0,
			blur: 10,
			opacity: 0.1,
			color: '#e2a754'
		}
	},
	colors: [ '#e2a754', '#3c4b78' ],
	markers: {
		size: 0,
		colors: 'white',
		strokeColors: '#7551FF',
		strokeWidth: 3,
		strokeOpacity: 0.9,
		strokeDashArray: 0,
		fillOpacity: 1,
		discrete: [],
		shape: 'circle',
		radius: 2,
		offsetX: 0,
		offsetY: 0,
		showNullDataPoints: true
	},
	tooltip: {
		theme: 'dark'
	},
	dataLabels: {
		enabled: false
	},
	stroke: {
		curve: 'smooth',
		type: 'line'
	},
	xaxis: {
		type: 'numeric',
		categories: [ 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB' ],
		labels: {
			style: {
				colors: '#A3AED0',
				fontSize: '12px',
				fontWeight: '500'
			}
		},
		axisBorder: {
			show: false
		},
		axisTicks: {
			show: false
		}
	},
	yaxis: {
		show: false
	},
	legend: {
		show: false
	},
	grid: {
		show: false,
		column: {
			color: [ '#7551FF', '#39B8FF' ],
			opacity: 0.5
		}
	},
	color: [ '#7551FF', '#39B8FF' ]
};

// src/components/Default.tsx
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Flex,
  Icon,
  Spinner,
  Stat,
  StatLabel,
  StatNumber,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { RootState } from "../../../../redux/store";
import { MdHandshake } from "react-icons/md";
import IconBox from "components/icons/IconBox";

export default function CommitmentToCallIndicator(props: {
  startContent?: JSX.Element;
  endContent?: JSX.Element;
  name: string;
  growth?: string | number;
  value: string | number;
}) {
  const { startContent, endContent, name, growth, value } = props;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "secondaryGray.700";
  const { loading } = useSelector((state: RootState) => state.data);
  const brandColor = useColorModeValue('brand.500', 'white');
  const boxBg = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');

  const stakeholders = useSelector(
    (state: RootState) => state.data.stakeholders
  );
  const selectedEntityIds = useSelector(
    (state: RootState) => state.data.selectedEntityIds
  );
  const selectedStrategyIds = useSelector(
    (state: RootState) => state.data.selectedStrategyIds
  );
  const [totalOutstandingCommittedAmount, setTotalOutstandingCommittedAmount] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!loading && stakeholders) {
      if (stakeholders.length) {
        let sumOutstandingCommittedAmount = 0;

        stakeholders.forEach((st) => {
          st.commitments.forEach((commitment) => {
            if (!selectedStrategyIds.includes(commitment.strategyId)) {
              return;
            }

            sumOutstandingCommittedAmount +=
              commitment.holder.outstandingCommittedAmount;
          });
        });

        setTotalOutstandingCommittedAmount(sumOutstandingCommittedAmount);
      }
    }
  }, [loading, stakeholders, selectedEntityIds, selectedStrategyIds]);

  const currencyFormatter = new Intl.NumberFormat("nl-BE", {
    style: "currency",
    currency: "EUR",
  });

  return (
    <Card>
      <Flex
        my="auto"
        h="100%"
        align={{ base: "center", xl: "center" }}
        justify={{ base: "center", xl: "center" }}
        marginLeft='20px'
      >
        <IconBox w='56px'
          h='56px'
          bg={boxBg} icon={<Icon w='32px' h='32px' as={MdHandshake} color={brandColor} />}
          marginRight="20px"
        />
        {startContent}

        <Stat my="auto" ms={startContent ? "18px" : "0px"} marginLeft='10px'>
          <StatLabel
            lineHeight="100%"
            color={textColorSecondary}
            fontSize={{ base: "xl", xs: "xs" }}
          >
            {name}
          </StatLabel>
          <StatNumber color={textColor} fontSize={{ base: "xl" }}>
            {loading ? (
              <Spinner size="sm" />
            ) : totalOutstandingCommittedAmount !== undefined ? (
              currencyFormatter.format(totalOutstandingCommittedAmount)
            ) : (
              <Spinner size="sm" />
            )}
          </StatNumber>
        </Stat>
        <Flex ms="auto" w="max-content">
          {endContent}
        </Flex>
      </Flex>
    </Card>
  );
}

import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationResult, MsalAuthenticationTemplate } from '@azure/msal-react';
import { loginRequest } from 'authConfig';
import { Outlet } from 'react-router-dom';

const ErrorComponent: React.FC<MsalAuthenticationResult> = ({ error }) => {

    // Handle the error or display a message
    if (error?.errorMessage?.includes('AADB2C90091')) {
        // The user has cancelled entering self-asserted information
        window.location.reload();
    }

    return <div />
  };

const ProtectedRoute = () => {
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
      errorComponent={ErrorComponent}>
          <Outlet />
    </MsalAuthenticationTemplate>
  );
};

export default ProtectedRoute;

import { Box, SimpleGrid } from '@chakra-ui/react';
import HistoricalValueChart from 'views/admin/default/components/HistoricalValueChart';
import ActualValueChart from 'views/admin/default/components/ActualValueChart';
import ReturnChart from 'views/admin/default/components/ReturnChart';
import PortfolioDetails from './components/PortfolioDetails';
import ActualValueIndicator from './components/ActualValueIndicator';
import InitialValueIndicator from './components/InitialValueIndicator';
import CommitmentToCallIndicator from './components/CommitmentToCallIndicator';
import DistributionsIndicator from './components/DistributionsIndicator';

export default function Dashboard() {
	return (
		<Box>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<SimpleGrid columns={{ base: 1, md: 2, xl: 1 }} gap='20px' >
					<InitialValueIndicator name='Initial Investment' value='€ 20.800.766,48' showHistoricalData/>
					<DistributionsIndicator name='Distributions' value='€ 26.800.766,48' showHistoricalData/>
					<ActualValueIndicator name='Actual value' value='€ 26.800.766,48'   showHistoricalData/>
					<CommitmentToCallIndicator name='Commitment to call' value='€ 5.000.000,00' />
				</SimpleGrid>
				<ActualValueChart />
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
				<ReturnChart  showHistoricalData/>
				<HistoricalValueChart showHistoricalData/>
			</SimpleGrid>
			<SimpleGrid columns={{ base: 1, md: 1, xl: 1 }} gap='20px' mb='20px'>
				<PortfolioDetails showHistoricalData/>
			</SimpleGrid>
		</Box>
	);
}
